import React from 'react'
import Img from 'gatsby-image'
import v4 from 'uuid'
import { useEntryAnimationFadeUp } from '../../helpers'
import { animated } from 'react-spring'
import { Link } from 'gatsby'
import Image from '../utils/Image'

const Column = ({ image, title, body, link }) => (
  <div className="tiles is-ancestor">
    <div className="tile">
      <div className="columns">
        <div className="column is-4">
          <Image
            image={image}
            style={{ display: 'block' }}
            className="mx-auto"
          />
        </div>
        <div className="column">
          <h4 className="title is-4">{title}</h4>
        </div>
      </div>
    </div>
    <br />
    <div className="tile">
      <p>{body}</p>
    </div>
    <br />
    <div className="tile">
      <Link to={link} className="button">
        Learn more
      </Link>
    </div>
  </div>
)

const SecondaryPitch = ({ columns }) => {
  const [bind, trail] = useEntryAnimationFadeUp(columns.length, {
    mass: 7.5,
    tension: 900,
    friction: 200,
  })

  const isOnCMS = typeof window !== 'undefined' && !!window.NetlifyCmsApp

  return (
    <section className="section has-background-black" {...bind}>
      <div className="container">
        <div className="columns">
          {!isOnCMS
            ? trail.map(({ y, ...rest }, i) => (
                <animated.div
                  key={v4()}
                  style={{
                    ...rest,
                    transform: y.interpolate(y => `translate3d(0,${y}px,0)`),
                  }}
                  className="column"
                >
                  <Column {...columns[i]} />
                </animated.div>
              ))
            : columns.map(Column)}
        </div>
      </div>
    </section>
  )
}

export default SecondaryPitch
