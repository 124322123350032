import React, { useLayoutEffect, useState, useRef } from 'react'
import { useTransition, animated } from 'react-spring'
import { Link } from 'gatsby'
import Image from './utils/Image'
import v4 from 'uuid'

const Carousel = ({
  slides,
  position = 'left',
  smallButtons = false,
  duration = 1000,
  autoplayDuration = 4000,
  animation = {},
}) => {
  const container = useRef()
  const interval = useRef()
  const [index, setSlide] = useState(0)
  const [height, setHeight] = useState(0)
  // Used to dynamically render the slides without anim
  // to get the slide with the biggest height
  const [initialRender, setInitialRender] = useState(false)
  const { current: carouselID } = useRef(v4())

  // Slide content position
  let positionClass
  switch (position) {
    case 'center':
      positionClass = ''
      break
    case 'right':
      positionClass = 'is-8'
      break
    default:
      positionClass = 'is-4'
  }

  const defaultFadeAnim = {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  }

  const transitions = useTransition(index, i => i, {
    ...defaultFadeAnim,
    ...animation,
    duration,
  })

  function autoplay() {
    // Already playing
    if (interval.current) return

    interval.current = setInterval(
      () => setSlide(state => (state + 1) % slides.length),
      autoplayDuration
    )

    return stopAutoplay
  }

  function goToSlide(i) {
    if (i > slides.length)
      throw Error(`
                You've done something wrong. Check goToSlide()
            `)

    stopAutoplay()
    setSlide(i)
  }

  function stopAutoplay() {
    if (interval.current) {
      clearInterval(interval.current)
      interval.current = null
    }
  }

  useLayoutEffect(() => {
    if (!container.current) return

    // Find biggest height of the nodes
    let height = 0
    container.current.childNodes.forEach(child => {
      if (child.clientHeight > height) {
        height = child.clientHeight
      }
    })
    setHeight(height)
    setInitialRender(true)

    const stop = autoplay()

    return stop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSlide = (item, i) => {
    const { title, subtitle, button, link, body, image } = item

    return (
      <section key={v4()} className="section is-relative">
        <div className="container is-relative" style={{ zIndex: 3 }}>
          <div
            className={`item-${i}`}
            key={`slider-${carouselID}-carousel-item-${i}`}
          >
            <div
              className={`tile ${positionClass} is-vertical`}
              style={{
                zIndex: 3,
              }}
            >
              {subtitle && (
                <div className="tile">
                  <h6 className="subtitle is-6">{subtitle}</h6>
                </div>
              )}
              <div className="tile is-child">
                <h2 className="title is-2 is-size-3-mobile">{title}</h2>
              </div>
              <div className="tile is-child">{body}</div>
              {button && link && button !== 'empty' && (
                <div className="tile is-child">
                  <Link to={link} className="button is-primary">
                    {button}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        {image && (
          <Image
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            image={image}
            key={`slider-${carouselID}-carousel-item-image-${i}`}
          />
        )}
      </section>
    )
  }

  const RenderNav = () => (
    <nav
      className="pagination is-rounded"
      role="navigation"
      aria-label="carousel navigation"
      style={{
        position: 'absolute',
        bottom: 20,
        left: '50%',
        transform: 'translate3d(-50%, 0, 0)',
      }}
    >
      <ul className="pagination-list">
        {new Array(slides.length).fill(null).map((_, i) => (
          <li key={`navigiation-slider-${carouselID}-${i}`}>
            <span
              className={`pagination-link ${index === i &&
                'is-current'} ${smallButtons && 'small'}`}
              aria-label={`Go to slide ${i + 1}`}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => goToSlide(i)}
            >
              {!smallButtons && i + 1}
            </span>
          </li>
        ))}
      </ul>
    </nav>
  )

  // Before rendering the carousel, we will put out all slides to find which has the biggest height
  // so we can use that then to render the carousel
  if (!initialRender) {
    return (
      <div ref={container} style={{ opacity: 0 }}>
        {slides.map((slide, i) => renderSlide(slide, i))}
      </div>
    )
  }

  return (
    <div className="is-relative" style={{ height }} onPointerLeave={autoplay}>
      {transitions.map(({ item, props, key }, i) => {
        return (
          <animated.div
            key={key}
            ref={container}
            style={{
              ...props,
              position: 'absolute',
              width: '100%',
            }}
          >
            {renderSlide(slides[item], i)}
          </animated.div>
        )
      })}
      {slides.length > 1 && <RenderNav />}
    </div>
  )
}

export default Carousel
