import React from 'react'
import v4 from 'uuid'
import { animated } from 'react-spring'
import { useEntryAnimationFadeUp } from '../../helpers'
import Image from '../utils/Image'
import Carousel from '../Carousel'

const items = [
  ({ title }) => <h1 className="title is-size-1 is-size-3-mobile">{title}</h1>,
  ({ buttons }) => (
    <div className="buttons is-centered">
      {buttons.map(({ text, link }) => (
        <a className="button is-primary is-rounded" key={v4()} href={link}>
          {text}
        </a>
      ))}
    </div>
  ),
]

const Hero = props => {
  const { image, slider } = props
  return (
    <>
      <section className="hero is-flex is-relative is-black">
        <div className="hero-body" style={{ padding: 0 }}>
          <div className="container has-text-centered">
            <Carousel
              slides={slider.slides}
              position="center"
              duration={1500}
              autoplayDuration={slider.timer}
              smallButtons={true}
            />
          </div>
        </div>
      </section>
      <Image image={image} nonStretched={true} />
    </>
  )
}

export default Hero

// const [bind, trail] = useEntryAnimationFadeUp(2);
// {trail.map(({ y, ...rest }, index) => {
//     return (
//         <animated.div
//             key={items[index]}
//             style={{
//                 ...rest,
//                 transform: y.interpolate(
//                     y => `translate3d(0,${y}px,0)`
//                 ),
//                 marginBottom: index === 0 ? '2rem' : ''
//             }}
//         >
//             {items[index](props)}
//         </animated.div>
//     );
// })}
