import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Hero from '../components/Home/Hero'
import Carousel from '../components/Carousel'
import MainPitch from '../components/Home/MainPitch'
import SecondaryPitch from '../components/Home/SecondaryPitch'
import { CTAContactForm } from '../helpers'

export const IndexPageTemplate = ({ hero, mainpitch, sectiontwo }) => {
  return (
    <>
      <Hero {...hero} />
      <MainPitch {...mainpitch} />
      <SecondaryPitch {...sectiontwo} />
      <section className="has-background-black">
        <Carousel slides={sectiontwo.slider} />
      </section>
      <section
        className="has-background-black has-text-centered"
        style={{ padding: '2rem' }}
      >
        <CTAContactForm />
      </section>
    </>
  )
}

IndexPageTemplate.propTypes = {
  hero: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    buttons: PropTypes.array,
  }),
  mainpitch: PropTypes.object,
  sectiontwo: PropTypes.object,
}

const IndexPage = ({ data, pageContext }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout pageContext={pageContext}>
      <IndexPageTemplate
        hero={frontmatter.hero}
        heading={frontmatter.heading}
        mainpitch={frontmatter.mainpitch}
        sectiontwo={frontmatter.sectiontwo}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        hero {
          slider {
            timer
            slides {
              title
              button
              link
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
        heading
        mainpitch {
          firstColumn {
            title
            description
            button
            link
          }
          secondColumn {
            title
            subtitle
            body
          }
        }
        sectiontwo {
          columns {
            image {
              childImageSharp {
                fixed(width: 60) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            title
            body
            link
          }
          slider {
            subtitle
            title
            body
            button
            link
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
