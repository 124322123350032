import React from 'react'
import { Link } from 'gatsby'
import { animated } from 'react-spring'
import v4 from 'uuid'
import { useEntryAnimationFadeLeft } from '../../helpers'

const items = [
  ({ firstColumn: { title } }) => (
    <div className="tile">
      <h6 className="title">{title}</h6>
    </div>
  ),
  ({ firstColumn: { description } }) => (
    <div className="tile">
      <h2 className="title is-2 is-size-3-mobile">{description}</h2>
    </div>
  ),
  ({ firstColumn: { button, link } }) => (
    <div className="tile">
      <Link className="button is-primary" to={link}>
        {button}
      </Link>
    </div>
  ),
  ({ secondColumn: { title } }) => (
    <div className="tile is-child">
      <h2 className="title is-3 is-size-3-mobile">{title}</h2>
    </div>
  ),
  ({ secondColumn: { subtitle } }) => (
    <div className="tile is-child">
      <h3 className="subtitle is-4">{subtitle}</h3>
    </div>
  ),
  () => <div className="tile is-1"></div>,
  ({ secondColumn: { body } }) => (
    <div className="tile is-child">
      <p>{body}</p>
    </div>
  ),
]

const MainPitch = props => {
  const [bind, trail] = useEntryAnimationFadeLeft(items.length, {
    mass: 10,
    tension: 1500,
    friction: 200,
  })

  function makeComponent(item, style) {
    const child = item(props)
    const Component = animated[child.type] || animated(child.type)
    const { x, ...rest } = style
    const cmpProps = {
      key: v4(),
      style: {
        ...rest,
        transform: x.interpolate(x => `translate3d(${x}px,0,0)`),
      },
    }
    return <Component {...child.props} {...cmpProps} />
  }

  function renderItems(startIdx, endIdx) {
    return trail.slice(startIdx, endIdx).map((styleProps, i) => {
      return makeComponent(items[i + startIdx], styleProps)
    })
  }

  return (
    <section className="section has-background-black-ter" {...bind}>
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="content">{renderItems(0, 3)}</div>
          </div>
          <div className="column is-1"></div>
          <div className="column is-7">
            <div className="tile is-ancestor is-vertical">
              <div className="tile is-parent" style={{ paddingBottom: 0 }}>
                {renderItems(3, 4)}
              </div>

              <div
                className="tile is-parent is-vertical"
                style={{ paddingTop: 0 }}
              >
                {renderItems(4, items.length)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainPitch
